<template>
    <div class="pricing_main">
        <NavBar/>
        <div class="pricing_container">
            <!--            -->
<!--            <div class="pricing_title-container">-->
<!--                <div class="pricing_head-content">-->
<!--                    <div class="pricing_title">-->
<!--                        Expert lead generation in your city-->
<!--                    </div>-->
<!--                    <div class="pricing_description">-->
<!--                        We are a tech-enabled solution to help you generate more buyer and seller leads. Sit back, watch-->
<!--                        the leads come in and close deals. Our AI based CRM and lead generation approach is proven to-->
<!--                        provide outsized ROI and will enable you to focus on what you do best: closing more deals.-->
<!--                    </div>-->

<!--                    <div class="pricing_get-btn-container">-->
<!--                        <b-button class="pricing_get-btn">Start Getting Leads</b-button>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="pricing_title-img">-->

<!--                </div>-->
<!--            </div>-->
            <!--            -->
            <div class="pricing_cards-content">
                <h4 class="pricing_cards-title">You Will Be Provided Exclusive Buyer and Seller Leads Every Month</h4>
                <p class="pricing_cards-description">Choose your package</p>
                <div class="pricing_cards">
                    <div class="card_item">
                        <div class="card_item-head">
                            <div class="flag_badge"></div>
                            <div class="card_label">Lite - Buyer or Seller Leads</div>
                            <div class="card_item-title">
                                $297<span>/per month</span>
                            </div>
                        </div>
                        <div class="card_item-body">
                            <div class="card_item-content">
                                <ul>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        Buyer or seller leads
                                    </li>
                                    <b-form-radio-group v-model="buyer_seller" class="radio_group" :options="options"
                                                        name="buyer-validation">
                                    </b-form-radio-group>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        Software for Real Estate Agents
                                    </li>

                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        $300 minimum ad budget
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        $297 one-time onboarding fee
                                    </li>
                                </ul>
                            </div>
                            <div class="card_item-agree-btn">
                                <div class="card_item-agree_check">
                                    <div class="card_item-agreement">
                                        <b-form-checkbox v-model="litePlan" name="check-button">
                                            I have read and agree to the
                                        </b-form-checkbox>&nbsp; <span @click="buyerOrSeller()"> Agreement</span>

                                    </div>
                                    <div class="card_item-agreement">
                                        <b-form-checkbox v-model="litePlanPolicies" name="check-button1">
                                            I have read and agree to the
                                        </b-form-checkbox>&nbsp; <span @click="liteBillingPolicies()"> Billing Policies</span>
                                    </div>
                                </div>
                                <div class="card_item-btn-container">
                                    <b-button class="card_item-btn" :disabled="litePlanCheck" @click="subscribeLite()">Select</b-button>
                                </div>
                            </div>
<!--                            <div class="card_item-btn-container">-->
<!--                                <b-button class="card_item-btn" @click="selectPlan()">Select</b-button>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div class="card_item bold_item">

                        <div class="card_item-head">
                            <div class="popular_badge"></div>
                            <div class="card_label">Pro - Buyer and Seller Leads</div>
                            <div class="card_item-title">
                                $597<span>/per month</span>
                            </div>
                        </div>
                        <div class="card_item-body">
                            <div class="card_item-content">
                                <ul>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        Both buyer and seller leads
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        Software For Real Estate Agents
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        Situational Coaching And Advice
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        First Look At New Product Features And Priority Access To New Features
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        $300 minimum ad budget
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"
                                             class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                        </svg>
                                        $597 one-time onboarding fee
                                    </li>
                                </ul>
                            </div>
                            <div class="card_item-btn-container">
                                <b-button class="card_item-btn" @click="subscribePro()">Select</b-button>
                            </div>
                        </div>
                    </div>
<!--                    <div class="card_item">-->
<!--                        <div class="card_item-head">-->
<!--                            <div class="card_item-title">-->
<!--                                Coming Soon-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="card_item-body">-->
<!--                            <div class="card_item-content">-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        Both buyer and seller leads-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        Software for Real Estate Agents-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        Situational Coaching And Advice-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        Get Our Help Customizing Templates Based On Your Needs-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        First Look At New Product Features And Priority Access To New Features-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        Executive Assistant To Help You Schedule Appointments-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#409EDC"-->
<!--                                             class="bi bi-check-lg" viewBox="0 0 16 16">-->
<!--                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
<!--                                        </svg>-->
<!--                                        AI Automation To Help You Convert Leads-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                            <div class="card_item-btn-container">-->
<!--                                <b-button class="card_item-btn" @click="selectUser()">Select</b-button>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->

                </div>
            </div>
            <!--            -->
            <div class="pricing_services-container">
<!--                <div class="pricing_services-img"></div>-->
<!--                <div class="pricing_services-content">-->
                    <div class="pricing_services-title">
                        Our agents have seen over 398% ROI and saved more than 20 hours a week, and you can do the same too.
                    </div>

                    <div class="pricing_get-btn-container">
                        <b-button class="pricing_get-btn" @click="selectUser()">Start Getting Leads</b-button>
                    </div>
<!--                    <div class="pricing_services-description">-->
<!--                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has-->
<!--                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a-->
<!--                            galley of type and scrambled it to make a type specimen book. It has survived not only five-->
<!--                            centuries,</p>-->

<!--                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has-->
<!--                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a-->
<!--                            galley of type and scrambled it to make a type specimen book. It has survived not only five-->
<!--                            centuries,</p>-->

<!--                    </div>-->
<!--                </div>-->
            </div>
            <!--            -->
            <div class="pricing_leads-container">
                <div class="pricing_leads-title">
                    Start getting leads and grow your real estate business today!
                </div>
                <div class="pricing_leads-description">
                    Note: Excess demand has created a limited number of spots. We will stop accepting new users soon.
                </div>
                <div class="pricing_leads-btn-container">
                    <b-button class="pricing_leads-btn" @click="selectUser()">Get Started
                        <b-icon icon="arrow-right"></b-icon>
                    </b-button>
                </div>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import NavBar from "../components/NavBar"
    import Footer from "../components/Footer"
    import {mapActions} from "vuex";

    export default {
        name: "Pricing",
        data() {
            return {
                buyer_seller: 0,
                options: [
                    {text: 'Buyer Leads', value: 0},
                    {text: 'Seller Leads', value: 1},
                ],
                litePlan: false,
                litePlanPolicies: false,
                litePlanData: '',
                proPlan: false,
                proPlanPolicies: false,
                proPlanData: '',
            }
        },
        components: {
            NavBar,
            Footer
        },
        computed: {
            litePlanCheck() {
                if (this.litePlan && this.litePlanPolicies) {
                    return false
                } else {
                    return true
                }
            },
            proPlanCheck() {
                if (this.proPlan && this.proPlanPolicies) {
                    return false
                } else {
                    return true
                }
            }
        },
        created() {
            this.getPlans()
                .then(res => {
                    if (res.status === 200) {
                        this.litePlanData = res.data.success[0]
                        this.proPlanData = res.data.success[1]
                    }
                })
        },
        methods: {
            ...mapActions(['getPlans']),
            selectUser(){
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            },
            selectPlan() {

            },
            buyerOrSeller() {
                let route = this.$router.resolve({path: '/buyer_or_seller'});
                window.open(route.href, '_blank');
            },
            liteBillingPolicies() {
                let route = this.$router.resolve({path: '/billing_policies'});
                window.open(route.href, '_blank');

            },
            subscribeLite() {
                let sendData = {
                    plan_id: this.litePlanData.id,
                    subscription_type: this.buyer_seller
                }
                this.$store.commit('CREATE_PLAN_DATA', sendData)
                this.$router.push('/register')
            },
            subscribePro() {
                let sendData = {
                    plan_id: this.proPlanData.id,
                    subscription_type: null
                }
                this.$store.commit('CREATE_PLAN_DATA', sendData)
                this.$router.push('/register')
            }
        }
    }
</script>

<style scoped lang="scss">
    .pricing {
        &_main {
            width: 100%;
        }

        &_container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &_title {
            font-size: 60px;
            font-weight: bold;
            color: $main_blue;
            @media screen and (max-width: 1600px) {
                font-size: 56px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 40px;
            }

            &-container {
                max-width: 1640px;
                width: 100%;
                display: flex;
                padding: 100px 0;
                justify-content: space-between;
                @media screen and (max-width: 1700px) {
                    max-width: 1600px;
                }
                @media screen and (max-width: 1600px) {
                    justify-content: center;
                    padding: 100px 60px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 100px 60px 50px;
                }

            }

            &-img {
                width: 960px;
                height: 460px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background: url("~@/assets/images/pricing_img.png");
                @media screen and (max-width: 1700px) {
                    width: 900px;
                }
                @media screen and (max-width: 1600px) {
                    width: 760px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        &_description {
            color: $grey;
            font-size: 20px;
            padding: 40px 0 55px;
            @media screen and (max-width: 1600px) {
                font-size: 18px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 16px;
            }
        }

        &_get-btn {
            background: $light_blue;
            color: $white;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 14px 42px;
            border: none;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 10px;

            &-container {
                padding: 40px 0 10px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        &_head-content {
            max-width: 700px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1600px) {
                max-width: 650px;
            }
            @media screen and (max-width: 1200px) {
                max-width: 500px;
            }
        }

        ///////////
        &_cards {
            width: 100%;
            display: flex;
            justify-content: center;
            /*align-items: center;*/
            padding: 50px 0;
            @media screen and (max-width: 1024px) {
                flex-wrap: wrap;
                padding: 30px 0;
            }
            @media screen and (max-width: 768px) {
                justify-content: center;
            }


            &-content {
                max-width: 1640px;
                width: 100%;
                padding: 100px 0 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @media screen and (max-width: 1700px) {
                    max-width: 1600px;
                }
                @media screen and (max-width: 1600px) {
                    padding: 100px  60px 30px;
                }
                @media screen and (max-width: 425px) {
                    padding: 100px  40px 30px;
                }
            }

            &-title {
                padding-top: 30px;
                font-size: 60px;
                font-weight: bold;
                color: $main_blue;
                text-align: center;
                max-width: 1400px;
                @media screen and (max-width: 1200px) {
                    font-size: 32px;
                    padding-top: 0;
                    max-width: 1000px;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 30px;
                    max-width: 900px;
                }
            }

            &-description {
                color: $grey;
                font-weight: 500;
                font-size: 20px;
                max-width: 730px;
                width: 100%;
                text-align: center;
                @media screen and (max-width: 1200px) {
                    font-size: 16px;
                }
            }

            & .card_item {
                width: 500px;
                min-height: 424px;
                height: 100%;
                background: #FFFFFF 0 0 no-repeat padding-box;
                box-shadow: 0 0 6px #00000029;
                border-radius: 10px;
                margin: 10px 25px;
                @media screen and (max-width: 1600px) {
                    width: 450px;
                    min-height: 400px;
                }
                @media screen and (max-width: 1440px) {
                    min-height: 360px;
                }
                @media screen and (max-width: 1200px) {
                    width: 400px;
                    min-height: 300px;
                }
                @media screen and (max-width: 1024px) {
                    width: 370px;

                }
                @media screen and (max-width: 425px) {
                    width: 370px;
                    margin: 10px 0;
                }
                & .popular_badge {
                    position: absolute;
                    top: -30px;
                    left: -30px;
                    width: 100px;
                    height: 100px;
                    background: url("~@/assets/images/popular.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }

                &-head {
                    width: 100%;
                    height: 100px;
                    background: $light_blue;
                    border-radius: 10px 10px 0 0;
                    display: flex;
                    flex-direction: column;
                    /*align-items: center;*/
                    padding: 10px 80px;
                    color: $white;
                    position: relative;
                    @media screen and (max-width: 1440px) {
                        height: 80px;
                    }
                    @media screen and (max-width: 1200px) {
                        height: 60px;
                        padding: 0 25px;
                    }
                    @media screen and (max-width: 1024px) {
                        padding: 0 20px;
                    }
                    & .card_label {
                        font-size: 22px;
                        font-weight: 500 ;
                    }
                    & .flag_badge {
                        position: absolute;
                        top: 0;
                        left: 20px;
                        width: 40px;
                        height: 75px;
                        background: url("~@/assets/images/flag_badge.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media screen and (max-width: 1440px) {
                        min-height: 280px;
                        height: 100%;
                    }
                }

                &-title {
                    font-weight: bold;
                    font-size: 38px;
                    line-height: 50px;
                    @media screen and (max-width: 1440px) {
                        font-size: 30px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 22px;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 20px;
                    }

                    & span {
                        font-size: 17px;
                        font-weight: 500;
                        @media screen and (max-width: 1440px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 1200px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 1024px) {
                            font-size: 13px;
                        }
                    }
                }

                &-content {
                    padding: 44px 40px 34px;

                    height: 100%;
                    @media screen and (max-width: 1440px) {
                        padding: 34px 24px;
                    }
                    @media screen and (max-width: 1200px) {
                        max-width: 650px;
                        padding: 28px 20px;
                    }

                    & .radio_group {
                        display: flex;
                        flex-direction: column;
                        padding: 0 36px;
                        ::v-deep .custom-control {
                            padding-bottom: 5px;
                        }
                    }
                    & ul  {
                        margin-bottom: 0;
                        min-height: 140px;
                        & li {
                            list-style-type: none;
                            padding-bottom: 10px;
                            @media screen and (max-width: 1440px) {
                                font-size: 15px;
                            }
                            @media screen and (max-width: 1200px) {
                                padding-bottom: 10px;
                            }

                            & svg {
                                margin-right: 20px;
                                @media screen and (max-width: 1440px) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }

                }
                &-agreement {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    padding: 2px 0;

                    & span {
                        line-height: 17px;
                        color: $light_blue;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                &-agree-btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &-agree_check {
                    display: flex;
                    flex-direction: column;
                }

                &-buyer-seller {
                    padding: 10px 0 20px;

                    & .buyer_seller-item {
                        font-size: 14px;
                        margin: 4px 0;

                        & svg {
                            margin-right: 5px;
                        }
                    }
                }

                &-radio-group {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                & .subscribe_btn {
                    width: 180px;
                    height: 46px;
                    color: $white;
                    background: $light_blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    margin: 22px 0;

                    &:active,
                    &:focus {
                        box-shadow: none;
                        border: none;
                        outline: none;
                    }
                }

                &-btn {
                    background: $light_blue 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 500;
                    font-size: 18px;
                    border: none;
                    width: 220px;
                    height: 56px;
                    @media screen and (max-width: 1440px) {
                        width: 200px;
                        height: 52px;
                    }
                    @media screen and (max-width: 1440px) {
                        width: 180px;
                        height: 48px;
                    }

                    &-container {
                        padding: 20px 0 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media screen and (max-width: 1440px) {
                            /*padding: 0;*/
                            height: 100%;
                            display: flex;


                        }
                    }
                }

                &.bold_item {
                    height: 520px !important;
                    @media screen and (max-width: 1600px) {
                        height: 490px !important;
                    }
                    @media screen and (max-width: 1440px) {
                        height: 460px !important;
                    }
                    @media screen and (max-width: 1200px) {
                        height: 420px !important;
                    }
                    @media screen and (max-width: 1024px) {
                        height: 490px !important;
                    }

                    & .card_item-head,
                    & .card_item-btn {
                        background: $main_blue;
                    }

                    & .card_item-body {
                        height: 80%;
                    }

                    & .card_item-btn-container {
                        height: 190px;
                        @media screen and (max-width: 1440px) {
                            height: 170px;
                        }
                        @media screen and (max-width: 1200px) {
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                }
            }
        }

        //////////
        &_services {
            &-container {
                max-width: 1640px;
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                flex-direction: column;
                padding: 10px 0 20px;
                @media screen and (max-width: 1700px) {
                    max-width: 1600px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 1400px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 1100px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 940px;
                }
            }

            /*&-img {*/
            /*    background: url("~@/assets/images/world_img.png");*/
            /*    width: 670px;*/
            /*    height: 670px;*/
            /*    background-position: center;*/
            /*    background-repeat: no-repeat;*/
            /*    background-size: cover;*/
            /*    @media screen and (max-width: 1440px) {*/
            /*        width: 600px;*/
            /*        height: 600px;*/
            /*    }*/
            /*    @media screen and (max-width: 1200px) {*/
            /*        width: 500px;*/
            /*        height: 500px;*/
            /*    }*/
            /*    @media screen and (max-width: 1024px) {*/
            /*        width: 440px;*/
            /*        height: 440px;*/
            /*    }*/
            /*}*/

            &-content {
                max-width: 600px;
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 1200px) {
                    max-width: 500px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 440px;
                }
            }

            &-title {
                font-size: 56px;
                font-weight: bold;
                color: $main_blue;
                @media screen and (max-width: 1600px) {
                    font-size: 50px;
                    padding: 0 20px;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 44px;
                    line-height: 52px;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 30px;
                    line-height: 36px;
                }
            }

            &-description {
                & p {
                    color: $grey;
                    font-size: 16px;
                    margin: 15px 0 30px;
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }
                }
            }
        }

        //////////
        &_leads {
            &-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px 0;
                @media screen and (max-width: 1024px) {
                    padding: 20px 0;
                }
            }

            &-title {
                padding-top: 30px;
                font-size: 36px;
                font-weight: bold;
                color: $main_blue;
                text-align: center;
                @media screen and (max-width: 1200px) {
                    font-size: 32px;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 30px;
                    padding-top: 20px;
                }
            }

            &-description {
                color: $grey;
                font-weight: 500;
                font-size: 20px;
                /*max-width: 570px;*/
                padding: 10px 0;
                width: 100%;
                text-align: center;
                @media screen and (max-width: 1200px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 15px;
                }
            }

            &-btn {
                background: $light_blue 0 0 no-repeat padding-box;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;
                width: 200px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                & svg {
                    margin-left: 12px;
                }

                &-container {
                    padding: 30px 0 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
</style>